// Logo.tsx
import React from 'react';

interface LogoProps {
  logoSrc: string;
}

const Logo: React.FC<LogoProps> = ({ logoSrc }) => {
  return <img src={logoSrc} alt="Company Logo" style={{ width: '100%', maxHeight: '100%', objectFit: 'contain' }} />;
};

export default Logo;
