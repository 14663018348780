import React from 'react';

const Header: React.FC = () => {
  return (
    <div className="header">
      <p style={{ maxWidth: '500px',
            fontFamily: 'Helvetica, Arial, sans-serif', // Use a modern font family
            fontSize: '22px'
    }}><strong>Mosaic Ventures</strong> is committed to creating and supporting ventures that leverage technology to create positive societal and environmental impact.</p>
    </div>
  );
};

export default Header;
