import React from 'react';
import { useSpring, animated, config } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import BackgroundImage from './BackgroundImage';
import Logo from './Logo';
import ContactInfo from './ContactInfo';
import Header from './Header';
import Footer from './Footer';

const LandingPage: React.FC = () => {
  const svgPath = process.env.PUBLIC_URL + '/background.svg'; // Path to your SVG file
  const logoSrc = process.env.PUBLIC_URL + '/logo512.png'; // Path to your PNG logo file

  // Parallax effect for the background image
  const parallaxBackground = useSpring({
    from: { transform: 'translate3d(0, 0, 0)' },
    to: async next => {
      while (true) {
        await next({ transform: 'translate3d(0, -2%, 0)' });
        await next({ transform: 'translate3d(0, 2%, 0)' });
      }
    },
    config: config.slow,
  });

  // Fade-in animation for the logo and header
  const fadeInLogoHeader = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: { duration: 1000 },
  });

  // Slide-in animation for the contact information
  const [contactInfoRef, contactInfoInView] = useInView({
    triggerOnce: true,
  });

  const slideInContactInfo = useSpring({
    opacity: contactInfoInView ? 1 : 0,
    transform: contactInfoInView ? 'translateX(0)' : 'translateX(100%)',
    config: { duration: 500 },
  });

  return (
    <div>
      <animated.div style={{ ...parallaxBackground, position: 'relative' }}>
        <BackgroundImage svgPath={svgPath} />
      </animated.div>
      <animated.div
        style={{
          ...fadeInLogoHeader,
          position: 'absolute',
          top: '35%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'black',
          padding: '20px', // Adjust the padding as needed
          color: 'white', // Text color inside the black box
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center', // Center the content horizontally
        }}
      >
        {/* Logo on the left */}
        <div style={{ marginRight: '5px' }}>
          <animated.div style={fadeInLogoHeader}>
            <Logo logoSrc={logoSrc} />
          </animated.div>
        </div>
        {/* Description and Contact information on the right */}
        <div style={{ textAlign: 'left' }}>
          <animated.div style={fadeInLogoHeader}>
            <Header />
          </animated.div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '10px',
            }}
            ref={contactInfoRef}
          >
            <animated.div style={slideInContactInfo}>
              <ContactInfo />
            </animated.div>
          </div>
        </div>
      </animated.div>
      {/* Copyright information */}
      <div style={{ marginTop: 'auto', textAlign: 'center' }}>
        <Footer />
      </div>
    </div>
  );
};

export default LandingPage;
