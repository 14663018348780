import '@fortawesome/fontawesome-svg-core/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import React from 'react';

const ContactInfo: React.FC = () => {
  const iconStyle = { color: 'white', marginRight: '10px', fontSize: '24px' }; // Adjust the fontSize property
  
  return (
    <div className="contact-info">
      <a href="mailto:contact@mosaicventures.co" style={{ textDecoration: 'none' }}>
        <FontAwesomeIcon icon={faEnvelope} style={iconStyle} />
      </a>
      <a href="https://www.linkedin.com/company/mosaicventures" style={{ textDecoration: 'none' }}>
        <FontAwesomeIcon icon={faLinkedin} style={iconStyle} />
      </a>
      <a href="https://x.com/mosaic_ventures" style={{ textDecoration: 'none' }}>
        <FontAwesomeIcon icon={faTwitter} style={iconStyle} />
      </a>
    </div>
  );
};

export default ContactInfo;
