import React from 'react';

interface BackgroundImageProps {
  svgPath: string;
}

const BackgroundImage: React.FC<BackgroundImageProps> = ({ svgPath }) => {
  return (
    <div
      style={{
        backgroundImage: `url(${svgPath})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
        width: '100%',
      }}
    />
  );
};

export default BackgroundImage;
