import React from 'react';

const Footer: React.FC = () => {
  return (
    <div className='footer'>
      <footer
        style={{
          textAlign: 'center',
          padding: '20px',
          backgroundColor: 'white',
          color: 'black',
          fontFamily: 'Helvetica, Arial, sans-serif', // Use a modern font family
          fontSize: '22px'
        }}
      >
        &copy; Copyright © {new Date().getFullYear()} Mosaic Ventures LLC. All Rights Reserved.
      </footer>
    </div>
  );
};

export default Footer;
